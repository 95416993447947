<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    title="维保报告"
    width="840px"
    top="2vh">
    <div id="reportContent" class="wrapper">
      <div class="title">维保报告</div>
      <el-descriptions :column="2" border style="margin: 10px 0">
        <el-descriptions-item label="项目名称">{{model.projectName}}</el-descriptions-item>
        <el-descriptions-item label="电梯名称">{{model.elevatorName}}</el-descriptions-item>
        <el-descriptions-item label="注册代码">{{model.regCode}}</el-descriptions-item>
        <el-descriptions-item label="维保类型">{{model.typeDesc}}</el-descriptions-item>
        <el-descriptions-item label="维保人员">
          {{model.worker1Name}}
          <template v-if="model.worker2Name">,{{model.worker2Name}}</template>
        </el-descriptions-item>
        <el-descriptions-item label="计划日期">{{model.planDate}}</el-descriptions-item>
        <el-descriptions-item label="完成时间">{{model.finishTime ? model.finishTime : "未完成"}}</el-descriptions-item>
      </el-descriptions>
      <table class="vm-table item-table">
        <tr>
          <th>序号</th>
          <th>维保项目</th>
          <th>基本要求</th>
          <th>重点检查</th>
          <th>结果</th>
        </tr>
        <tr v-for="(item,index) in model.details.details" :key="index">
          <td>{{index + 1}}</td>
          <td>{{item.content}}</td>
          <td>{{item.required}}</td>
          <td>{{item.important}}</td>
          <td>{{item.result}}</td>
        </tr>
      </table>
    </div>
    <span slot="footer">
      <el-button v-print="'#reportContent'" type="primary">打印</el-button>
    </span>
  </el-dialog>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        model: {
          details: {},
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        this.contentLoading = true;
        this.$http.get(`wbs/${this.model.id}`).then(data => {
          this.contentLoading = false;
          this.model = data;
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.wrapper {
  border: 1px #111 dashed;
}

.title {
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #222;
  text-align: center;
  border-bottom: 2px #222 solid;
}

.item-table {
  width: 100%;
}
</style>
