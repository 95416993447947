<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择维保模板"
    class="small-padding"
    width="1000px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="wbTemplates">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="模板名称">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" label="模板名称" align="center"></el-table-column>
      <el-table-column label="地区" align="center">
        <template slot-scope="scope">
          {{scope.row.provinceName + scope.row.cityName}}
        </template>
      </el-table-column>
      <el-table-column prop="typeDesc" label="保养类型" align="center"></el-table-column>
      <el-table-column prop="elevatorTypeDesc" label="电梯类型" align="center"></el-table-column>
      <el-table-column prop="updateTime" label="修改时间" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" width="80">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        filter: {
          name: "",
        },
      };
    },
    methods: {
      open() {
        this.filter={};
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.$emit("select", row);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
