<template>
  <div class="maintenance-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="wbs">
      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open()">{{$l("wb.add", "新增维保计划")}}</el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('wb.no','工单编号')">
            <el-input v-model.trim="filter.no" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('elevator.name','电梯名称')">
            <el-input v-model.trim="filter.elevatorName" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('elevator.name','电梯名称')">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('wb.type','维保类型')">
            <vm-dict-select v-model="filter.type" type="wbType"></vm-dict-select>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="no" :label="$l('wb.no','工单编号')" align="center" width="135"></el-table-column>
      <el-table-column prop="elevatorName" :label="$l('elevator.name','电梯名称')" align="center">
        <template slot-scope="scope">
          {{scope.row.regCode}}<br/>
          {{scope.row.elevatorName}}
        </template>
      </el-table-column>
      <el-table-column prop="templateName" label="维保模板" align="center"></el-table-column>
      <el-table-column prop="typeDesc" label="维保类型" align="center" width="100"></el-table-column>
      <el-table-column :label="$l('wb.worker','维保工')" align="center">
        <template slot-scope="scope">
          {{scope.row.worker1Name}}
          <template v-if="scope.row.worker2Name">,{{scope.row.worker2Name}}</template>
        </template>
      </el-table-column>
      <el-table-column prop="planDate" :label="$l('wb.planDate','计划日期')" align="center" width="100"></el-table-column>
      <el-table-column :label="$l('common.status','状态')" align="center">
        <template slot-scope="scope">
          {{scope.row.statusDesc}}<br/>
          <el-tag v-if="scope.row.status==='plan'&&scope.row.overdue===1" effect="dark" type="warning">{{$l("wb.dueToday", "今日到期")}}</el-tag>
          <el-tag v-if="scope.row.status==='plan'&&scope.row.overdue===2" effect="dark" type="danger">{{$l("wb.overdue", "已超期")}}</el-tag>
          <el-tag v-if="scope.row.status==='finished'" effect="dark" type="success">{{scope.row.finishTime}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.report.open(scope.row.id)">报告</el-button>
          <el-button type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <wb-report ref="report"></wb-report>
  </div>
</template>
<script>
  import EditPage from "./WbEdit";
  import WbReport from "./WbReport";
  import loginUtil from "@/util/loginUtil";

  export default {
    components: {EditPage, WbReport},
    data() {
      return {
        filter: {
          no: "",
          elevatorName: "",
          regCode: "",
          type: "",
          companyId: loginUtil.getCompanyCode(),

        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(this.$l("common.deleteTip", "确定删除{key}吗？", {key: row.no}), this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("wbs", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.no + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
