<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('wb.edit','编辑维保工单'):$l('wb.add','新增维保计划')"
    width="530px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('wb.labelWidth','80px')">
      <el-form-item label="维保模板" prop="details.id" :rules="$rule.notNull">
        <vm-select-input
          :value="model.details.name"
          placeholder="选择维保模板"
          @select="$refs.wbTemplateSelect.open()"></vm-select-input>
      </el-form-item>
      <el-form-item :label="$l('elevator.elevator','电梯')" prop="elevatorName" :rules="$rule.notNull">
        <vm-select-input
          :value="model.elevatorName"
          :placeholder="$l('elevator.select','选择需要维保的电梯')"
          @select="$refs.elevatorSelect.open()"></vm-select-input>
      </el-form-item>
      <el-form-item label="计划日期" prop="planDate" :rules="$rule.notNull">
        <el-date-picker
          v-model="model.planDate"
          type="date"
          :placeholder="$l('common.select','选择维保日期')"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item :label="$l('wb.worker','维保工')+' 1'" prop="worker1Id" :rules="$rule.notNull">
        <vm-select-input
          clearable
          :value="model.worker1Name"
          :placeholder="$l('wb.selectWorkerTip','选择电梯后自动带出，也可手动修改')"
          @clear="clearWorker(1)"
          @select="$refs.userSelectM1.open()"></vm-select-input>
      </el-form-item>
      <el-form-item :label="$l('wb.worker','维保工')+' 2'" prop="worker2Id">
        <vm-select-input
          clearable
          :value="model.worker2Name"
          :placeholder="$l('wb.selectWorkerTip','选择电梯后自动带出，也可手动修改')"
          @clear="clearWorker(2)"
          @select="$refs.userSelectM2.open()"></vm-select-input>
      </el-form-item>
      <el-form-item label="备注" prop="note">
        <el-input v-model="model.note" type="textarea" :rows="2" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <elevator-select ref="elevatorSelect" @select="elevatorSelect"></elevator-select>
    <wb-template-select ref="wbTemplateSelect" @select="wbTemplateSelect"></wb-template-select>
    <user-select ref="userSelectM1" @select="selectWorker(1,$event)"></user-select>
    <user-select ref="userSelectM2" @select="selectWorker(2,$event)"></user-select>
  </el-dialog>
</template>
<script>
  import ElevatorSelect from "@/views/elevator/ElevatorSelect";
  import WbTemplateSelect from "@/views/wbTemplate/Select";
  import UserSelect from "@/views/user/UserSelect";

  export default {
    components: {ElevatorSelect, UserSelect, WbTemplateSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          no: "",
          elevatorId: "",
          elevatorName: "",
          planDate: "",
          worker1Id: "",
          worker2Id: "",
          worker1Name: "",
          worker2Name: "",
          note: "",
          details: {},
          templateName: "",
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`wbs/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
          });
        }
      },
      wbTemplateSelect(row) {
        this.model.details = row;
      },
      elevatorSelect(row) {
        this.model.elevatorId = row.id;
        this.model.elevatorName = row.name;
        this.model.worker1Id = row.wbWorker1Id;
        this.model.worker2Id = row.wbWorker2Id;
        this.model.worker1Name = row.wbWorker1Name;
        this.model.worker2Name = row.wbWorker2Name;
      },
      selectWorker(index, row) {
        let idKey = `worker${index}Id`;
        let nameKey = `worker${index}Name`;
        this.model[idKey] = row.id;
        this.model[nameKey] = row.name;
      },
      clearWorker(index) {
        let idKey = `worker${index}Id`;
        let nameKey = `worker${index}Name`;
        this.model[idKey] = "";
        this.model[nameKey] = "";
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("wbs", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.tip {
  margin-top: -8px;
  margin-bottom: 20px;
}
</style>
